import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import agent from "api/agent";
import CustomSelect from "./CustomSelect";
import { IBusinessEventByName } from "models/businessEvent/businessEventByName";
import { FileUploadResult } from "models/common/fileUploadResult";
import FileUploadWidget from "./FileUploadWidget";
import { CreateAttendee } from "models/attendee/createAttendee";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { IAttendeeAttachment } from "models/attendee/attendeeAttachment";

const ApplyForm: React.FC = () => {
  const formFieldsDefault = {
    name: "",
    email: "",
    phoneNumber: "",
    role: "",
    description: "",
    companyName: "",
    category: "",
  };

  const swal = withReactContent(Swal);

  const successAlertContent = () => {
    swal.fire({
      title: "Thank you for your application",
      text: "We registered your information",
      icon: "success",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const errorAlertContent = (errorMessage: string) => {
    swal.fire({
      title: "Application was not registered",
      text: errorMessage,
      icon: "error",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const checkBoxDefaults = {
    gdpr: false,
    entryRequlations: false,
  };

  const [state, setState] = useState({
    submitting: false,
    submitted: false,
    formFields: {
      ...formFieldsDefault,
    },
    checkboxes: {
      ...checkBoxDefaults,
    },
  });

  const [businessEventOptions, setBusinessEventOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [businessEventFiles, setBusinessEventFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [fileToUpload, setFileToUpload] = useState<FileUploadResult>(null);
  const [fileMissingErrorMessage, setFileMissingErrorMessage] = useState("");

  useEffect(() => {
    agent.BusinessEvent.getAvailable().then(
      (businessEvents: IBusinessEventByName[]) => {
        if (businessEvents) {
          const options = businessEvents?.map((event) => ({
            value: event.id,
            label: event.name,
          }));

          const files = businessEvents?.map((event) => ({
            url: event?.termsAndConditions.url,
            title: event?.termsAndConditions.title,
          }));
          setBusinessEventFiles(files);
          setBusinessEventOptions(options);
        }
      }
    );
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    let attachment: IAttendeeAttachment | null = null;
    if (fileToUpload) {
      attachment = {
        title: fileToUpload.fileName,
        url: fileToUpload.url,
      };
    }

    const formFields = { ...state.formFields };
    const attendee: CreateAttendee = {
      ...formFields,
      businessEventId: selectedOption.value,
      attachment: attachment,
    };

    agent.Attendee.create(attendee)
      .then(() => {
        setState({ ...state, formFields: formFieldsDefault, submitted: true });
        setFileToUpload(null);
        setSelectedOption(null);
        successAlertContent();
      })
      .catch(() => {
        errorAlertContent(`Something went wrong please try again later`);
      });
  };

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setState({
      ...state,
      formFields: {
        ...state.formFields,
        [name]: type === "checkbox" ? checked : value,
      },
    });
  };

  const checkBoxChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked } = e.target;
    setState({
      ...state,
      checkboxes: {
        ...state.checkboxes,
        [name]: type === "checkbox" ? checked : false,
      },
    });
  };

  const textAreaChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setState({
      ...state,
      formFields: {
        ...state.formFields,
        [name]: value,
      },
    });
  };

  const getCurrentFile = (option: any) => {
    const currentBusinessEventIndex = businessEventOptions.findIndex(
      (eventOption) => eventOption.value == option.value
    );

    const file = businessEventFiles[currentBusinessEventIndex];
    setSelectedFile(file);
  };

  const selectBusinessEvent = (option: any) => {
    setSelectedOption(option);
    getCurrentFile(option);
  };

  return (
    <>
      <section
        className="contact-area"
        style={{ direction: "ltr", paddingBottom: "120px" }}
      >
        <div className="container">
          <div className="row h-100 align-items-center contact-form">
            {/* ... */}
            <div
              className="col-lg-12"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <form onSubmit={onSubmit} id="applyForm">
                <legend style={{ textAlign: "center", fontSize: "26px" }}>
                  Upload a nominee
                </legend>
                <div className="row">
                  {/* ... */}

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Business Event*</label>
                      <CustomSelect
                        options={businessEventOptions}
                        setSelectedOption={selectBusinessEvent}
                        selectedOption={selectedOption}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="category">Industry/category</label>
                      <input
                        type="text"
                        className="form-control"
                        name="category"
                        id="category"
                        required={false}
                        value={state.formFields.category}
                        onChange={inputChangeHandler}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Name of the nominee*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        required={true}
                        data-error="Please enter your name"
                        value={state.formFields.name}
                        onChange={inputChangeHandler}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Position*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="role"
                        id="role"
                        required={true}
                        data-error="Please enter your position"
                        value={state.formFields.role}
                        onChange={inputChangeHandler}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Company*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        id="companyName"
                        required={true}
                        data-error="Please enter your company"
                        value={state.formFields.companyName}
                        onChange={inputChangeHandler}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">E-mail*</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        required={true}
                        data-error="Please enter your e-mail"
                        value={state.formFields.email}
                        onChange={inputChangeHandler}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Phone number*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNumber"
                        id="phoneNumber"
                        required={true}
                        data-error="Please enter your phone number"
                        value={state.formFields.phoneNumber}
                        onChange={inputChangeHandler}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="description">
                        Description (Some information about the nominee,
                        LinkedIn profile link or CV, etc.)*
                      </label>
                      <textarea
                        name="description"
                        className="form-control"
                        id="description"
                        cols={30}
                        rows={4}
                        required={true}
                        data-error="Write your message"
                        value={state.formFields.description}
                        onChange={textAreaChangeHandler}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="form-group"></div>
                  <div className="col-lg-12">
                    <FileUploadWidget
                      setFileUploadResult={setFileToUpload}
                      fileUploadResult={fileToUpload}
                      setFileMissingErrorMessage={setFileMissingErrorMessage}
                    />
                    <p>* Attachments only in PDF</p>
                    <div
                      className="help-block with-errors"
                      style={{ color: "#FF2D55" }}
                    >
                      {fileMissingErrorMessage}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        id="gdpr"
                        type="checkbox"
                        name="gdpr"
                        required={true}
                        checked={state.checkboxes.gdpr}
                        data-error="accept"
                        onChange={checkBoxChangeHandler}
                        style={{ marginRight: "10px" }}
                      />
                      <label htmlFor="name" style={{ paddingRight: "5px" }}>
                        Accept our
                      </label>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                      <div className="help-block with-errors"></div>
                      {selectedFile && (
                        <>
                          <input
                            id="entryRequlations"
                            type="checkbox"
                            name="entryRequlations"
                            required={true}
                            checked={state.checkboxes.entryRequlations}
                            data-error="Write your message"
                            onChange={checkBoxChangeHandler}
                            style={{ marginRight: "10px" }}
                          />
                          <label htmlFor="name" style={{ paddingRight: "5px" }}>
                            Accept
                          </label>
                          {selectedFile && selectedFile.url != "" ? (
                            <a
                              href={selectedFile.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms And Conditions
                            </a>
                          ) : (
                            <label>Terms And Conditions</label>
                          )}
                          <div className="help-block with-errors"></div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-lg-12 col-md-12"
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "20px",
                    }}
                  >
                    <button type="submit" className="btn btn-primary">
                      Send
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplyForm;
